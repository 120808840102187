.ManageFAQ_Container {
  background: #f4f7ff;
  padding: 0 30px 30px;
}

.ManageFAQ_Link {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
}

.ManageFAQ_Link_Blue {
  color: #2b6fed;
  cursor: pointer;
}

.ManageFAQ_Link_Grey {
  color: #181d25;
  cursor: pointer;
}

.ManageFAQ_Tag {
  color: #181d25;
  font-size: 32px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: normal;
  margin-bottom: 25px;
}

.ManageFAQ_Dropdown_Container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.ManageFAQ_Dropdown_Width {
  width: 175px;
}

.faq_preview_image {
  height: 100px;
  width: 175px;
  object-fit: cover;
  border: 1px solid #ececec;
  border-radius: 8px;
}

.faq_image_close_icon {
  fill: red !important;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 18px !important;
  cursor: pointer;
}

.faq_image_pad {
  padding: 0 0px 0px 25px;
  position: relative;
  width: 175px;
}

.faq_image_pad_accordion {
  margin-top: 15px;
  padding: 0 25px 0px 0px;
  position: relative;
  width: 175px;
}

.faq_image_box {
  margin-bottom: 30px;
}

.ManageFAQ_Dropdown {
  color: #181d25;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
  appearance: none;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  background: #fff;
  height: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 24px 0 12px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 7px center;
  background-size: 1em;
  margin-right: 15px;
  margin-bottom: 15px;
}

.searchbtn {
  color: #fff;
  font-weight: 500;
}

.ManageFAQ_Dropdown:hover {
  opacity: 2 !important;
  font-weight: 800;
  padding: 0 24px 0 12px;
}

.ManageFAQ_Search_Button {
  border-radius: 8px;
  background: #2b6fed;
  width: 112px;
  height: 40px;
  cursor: pointer;
  color: #fdfefe;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.ManageFAQ_Button_Container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
}

.ManageFAQ_Button {
  border: none !important ;
  white-space: nowrap;
  width: fit-content !important;
  display: flex;
  align-items: center;
  height: 43px;
  border-radius: 20px;
  background: rgba(200, 200, 200, 0.32);
  padding: 0 30px;
  color: #181d25;
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 15px;
  cursor: pointer;
}

.MuiInputLabel-outlined {
  font-family: inherit !important;
}

.ManageFAQ_Button:hover,
.ManageFAQ_Button_active {
  background-color: #2b6fed;
  color: white;
  font-weight: 800;
}

.ManageFAQ_Button_DIV {
  display: flex;
  overflow-x: scroll;
  margin-right: 20px;
  -ms-overflow-style: none;
}

.question_Image {
  width: 50%;
}

.ManageFAQ_ADD_Button {
  width: 219px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fc8434;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.ManageFAQ_Question_Show.ManageFAQ_Question_empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 550px;
}

.ManageFAQ_Question_Show {
  overflow-y: auto;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 0px 14px 0px #f4f6fb;
  margin-bottom: 30px;
}

.Show {
  display: block;
}

.Hide {
  display: none;
}

.Accordion_Container {
  padding: 10px;
}

.Accordion_Border {
  box-shadow: none !important;
}

.Accordion_All_Component > .MuiAccordionSummary-content {
  justify-content: space-between;
}

.Accordion_Settings {
  position: relative;
}

.Edit_Delete {
  display: flex;
  align-items: center;
  position: absolute;
  top: 12%;
  padding: 10px;
  right: 40px;
  gap: 18px;
}

.Bin_Component {
  position: relative;
  z-index: 9;
  cursor: pointer;
}

.Question_Format {
  cursor: pointer;
  color: #181d25;
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 85%;
}

.Answer_Format {
  color: #181d25;
  font-family: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  display: block;
}

.faq_answer_accordion {
  display: block !important;
}

.ManageFAQ_Empty_Text {
  color: #212121;
  text-align: center;
  font-family: inherit;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 30px;
}

.Question_Img_div {
  display: flex;
  justify-content: center;
}

.Remove_Vehicles_FAQ {
  min-width: 216px;
  height: 50px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px solid #e46467;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e46467;
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 20px;
  cursor: pointer;
}

.ADD_QUESTION_Btt {
  border-radius: 8px;
  display: flex;
  width: 204px;
  background-color: #2b6fed;
  border: 1px solid #2b6fed;
  height: 50px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 20px;
  cursor: pointer;
}

.ManageFAQ_Final_button_Container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  padding: 25px 0 0px;
  width: 50%;
  background-color: white;
  outline: none;
  border-radius: 8px;
  box-shadow: 4px 0px 14px 0px rgba(0, 0, 0, 0.1);
}

.modal_Heading_Container {
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 25px;
}

.Modal_Field_Container {
  padding: 0 25px;
  margin-bottom: 25px;
}

.modalHeading {
  color: #181d25;
  font-family: inherit;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Close_Button {
  cursor: pointer !important;
}

.HR {
  background: rgba(24, 29, 37, 0.1);
  box-shadow: 4px 0px 14px 0px #f4f6fb;
  width: 100%;
  height: 1px;
  margin: 20px 0;
}

.QueHR {
  background: rgba(24, 29, 37, 0.1);
  box-shadow: 4px 0px 14px 0px #f4f6fb;
  width: 100%;
  height: 1px;
}

.Modal_Text_Field {
  width: 100% !important;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #eeeeee !important;
}

.Modal_Text_Field .MuiInputBase-root {
  color: #909090 !important;
  font-family: inherit !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.Modal_Text_Field .MuiInputBase-root .MuiInputBase-input {
  color: black;
  background: #f9f9f9 !important;
  border-radius: 8px;
}

.MuiSelect-select:focus {
  border-radius: 0 !important;
  background-color: transparent !important;
}

.Modal_Button_Container {
  padding: 0 25px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ModalCancelDiv {
  cursor: pointer;
  width: 30%;
  height: 44px;
  border: 1px solid #2b6fed;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2b6fed;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-transform: uppercase;
  margin-right: 25px;
}

.Modal_Button_Container .ModalConfirmDiv,
.Modal_Button_Container .ModalConfirmDiv:hover {
  width: 30%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #2b6fed;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  line-height: 21px;
  text-transform: uppercase;
  cursor: pointer;
}

.addQuePaper {
  overflow-y: auto !important;
  border-radius: 8px;
  padding: 25px 0 0px;
  width: 60%;
  height: 80vh;
  background-color: white;
  outline: none;
  box-shadow: 4px 0px 14px 0px rgba(0, 0, 0, 0.1);
}

.Modal_Select_Field {
  position: relative;
  width: 100% !important;
  height: auto !important;
  border-radius: 8px !important;
  border: 1px solid #eeeeee;
  background: #f9f9f9;
}

.Modal_Select_Field2 {
  position: relative;
  width: 100% !important;
  height: auto !important;
  border-radius: 8px !important;
  border: 1px solid #eeeeee;
  background: #f9f9f9;
}

.Modal_Select_Field_Text {
  /*color: #f9f9f9 !important;*/
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.Modal_Select_Field .MuiSelect-icon {
  display: none;
}

.Down {
  position: absolute;
  top: 45%;
  right: 4%;
  width: 12px;
  cursor: pointer;
}

.Down2 {
  position: absolute;
  top: 45%;
  right: 4%;
  width: 12px;
  cursor: pointer;
}

.Add_Que_Text_Area {
  resize: none;
  color: #000 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 100%;
  height: 174px;
  background-color: #f9f9f9;
  border: 1px solid #eeeeee;
  padding: 16px 12px;
  outline: none;
  border-radius: 8px;
}

.Add_Que_Imge_Div {
  position: relative;
  width: 100%;
  height: 164px;
  border-radius: 8px;
  border: 2px dashed rgba(143, 146, 161, 0.2);
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Add_Que_Imge_Div_upload {
  position: relative;
  width: 175px;
  height: 100px;
  border-radius: 8px;
  border: 2px dashed rgba(143, 146, 161, 0.2);
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#faq_image_upload {
  display: none;
}

.faq_preview_label {
  width: 175px;
}

.faq_upload_label {
  width: 100%;
}

.Upload_Image_Text {
  color: #212121;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
  cursor: pointer;
}

.Upload {
  cursor: pointer;
  margin-bottom: 10px;
}

.Add_Que_Imge_Div input[type="file"],
.Edit_Que_Img_Div input[type="file"] {
  font-size: 100px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0 !important;
  cursor: pointer !important;
}

.paper_Delete_Category {
  padding: 35px 0 35px;
  width:600px;
}

.Delete_Category_Container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.Delete_Category_Heading {
  color: #181d25;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: normal;
  margin-bottom: 15px;
}

.Delete_Category_Text {
  color: #181d25;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 35px;
}

.Delete_Category_Button_Div {
  justify-content: center !important;
}

.Button_Size_Delete {
  width: 35% !important;
}

.Edit_Que_Img_Container {
  padding: 0 25px;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 25px;
}

.Edit_Que_Img_Div {
  width: 20% !important;
  height: 106px !important;
  margin-right: 10px !important;
}

.Accordion_Container .MuiAccordionSummary-expandIcon {
  padding: 0;
}

.Accordion_Container .MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px !important;
}

.Accordion_Container .MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
