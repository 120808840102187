.MuiDialog-root .MuiDialogContent-dividers {
  border-bottom: 0;
}
.pendingColor{
  border-radius: 20px;
   text-transform: capitalize  !important;
   background-color:#FFEDE0 !important;
   color:#FC8434 !important
}
.resColor{
  border-radius: 20px;
   text-transform: capitalize !important ;
   background-color:#eefbf1 !important;
   color:#20a258 !important
}
.select-meeting.MuiDialog-root .MuiDialogContent-dividers,
.edit-profile.MuiDialog-root .MuiDialogContent-dividers {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.add-document .image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed lightgray;
  margin-top: 20px;
  padding: 30px 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.modal-document-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px 15px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background-color: #f9f9f9;
  gap: 10px;
}

.modal-document-box .left-side-document {
  display: flex;
  align-items: center;
  gap: 15px;
}

.modal-document-box .left-side-document img {
  width: 20px;
  height: 25px;
}

.modal-document-box .left-side-document p {
  font-size: 14px;
  word-break: break-word;
}

.modal-document-box .right-side-document svg {
  color: rgba(0, 0, 0, 0.54);
}

.modal-document-box .right-side-document {
  cursor: pointer;
}

.dialog-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px !important;
}

.dialog-heading h6 {
  font-weight: 600;
  line-height: 24px;
}

.dialog-input {
  padding: 15px 26px 15px 12px;
  border: 1px solid #f0f0f0;
  font-size: 14px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  outline: none;
  color: #494949;
}

.add-document .dialog-input,
.add-meeting .dialog-input {
  background-color: #f9f9f9;
  border: 1px solid #f0f0f0;
}

.meeting-minute-dialog .MuiCheckbox-colorSecondary.Mui-checked {
  color: #fc8434;
}

.add-faq-dialog .dialog-input {
  margin-top: 26px;
}

.add-faq-dialog .error,
.add-document .error {
  margin-top: 5px;
  color: red;
  font-size: 12px;
}

.dialog-select-input {
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  font-size: 14px;
  padding: 15px 26px 15px 12px;
  color: #494949;
  background-color: white;
}

.add-faq-dialog textarea {
  resize: none;
  background-color: #f9f9f9 !important;
  height: 150px !important;
}

.add-faq-dialog input {
  background-color: #f9f9f9;
  color: black;
}

.add-faq-dialog select {
  background-color: #f9f9f9;
  color: black;
}

.category.dialog-select-input {
  margin-bottom: 20px;
}

.dialog-textarea-input {
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  font-size: 14px;
  padding: 15px 26px 15px 12px;
  color: #494949;
  background-color: white;
  height: 100px;
  outline: none;
}

.add-document .image-box {
  background-color: #f9f9f9;
  border-color: #f0f0f0;
}

.add-document .image-box p {
  margin-top: 10px;
  color: #adadad;
  font-size: 14px;
}

.add-document .file-name {
  word-break: break-all;
  color: #2b6fec;
  font-weight: bold;
}

.delete-document .MuiDialogContent-root {
  padding: 10px 0;
}

.add-faq-dialog .dialog-textarea-input {
  margin-top: 26px;
}

.dialog-button-group {
  flex-direction: row;
}

.delete-dialog .comment-image {
  margin-bottom: 10px;
}

.delete-dialog h6 {
  margin-bottom: 15px;
  font-weight: 600;
}

.delete-dialog p {
  color: gray;
}

.dialog-button-group button {
  width: 150px;
}

.delete-document .MuiDialogContent-root {
  margin: 20px;
}

.delete-document .MuiDialogContent-root img,
.cancel-meeting-dialog .MuiDialogContent-root img,
.schedule-dialog .MuiDialogContent-root img,
.save-lease img {
  margin-bottom: 20px;
}

.delete-document h6,
.cancel-meeting-dialog h6,
.schedule-dialog h6,
.save-lease h6 {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.delete-document p,
.cancel-meeting-dialog p,
.schedule-dialog p,
.save-lease p {
  margin-bottom: 15px;
  color: #181d25;
  font-size: 14px;
  line-height: 20px;
}

.dashboard-sidebar .list-menu p {
  font-size: 16px;
}

.delete-document .dialog-button-group,
.cancel-meeting-dialog .dialog-button-group,
.delete-dialog .dialog-button-group {
  flex-direction: row;
  justify-content: center;
}

.delete-document .dialog-button-group button,
.delete-dialog .dialog-button-group button {
  width: 200px;
}

.resolutions .choose-meeting {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke-dasharray='8' stroke='royalblue' stroke-width='2' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  color: #2b6fec;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.resolutions .choose-meeting span {
  font-size: 14px;
}

.resolutions .change-meeting {
  border: 1px solid #2b6fec;
  color: #2b6fec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 12px 15px;
  border-radius: 5px;
}

.resolutions .change-meeting span:first-child {
  color: black;
  font-size: 14px;
}

.resolutions .change-meeting span:last-child {
  cursor: pointer;
  font-size: 14px;
}

.select-meeting .list-heading span {
  font-weight: 600;
  font-family: "Gotham_Bold", sans-serif !important;
}

.select-meeting .dialog-button-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
}

.select-meeting .dialog-button-group .selected-meeting {
  font-weight: 600;
}

.select-meeting .dialog-button-group .button-group button {
  margin-left: 10px;
}

.meeting-minute-row {
  border-bottom: 2px solid #f7f9fe;
}

.resolution-menu .szh-menu-container {
  position: absolute;
  z-index: 9999999;
}

.szh-menu-container ul {
  border-radius: 6px;
  border: 0;
  box-shadow: 4px 0px 14px #e9e9e9;
  z-index: 9999999;
}

.szh-menu-container ul li {
  font-size: 14px;
  border-bottom: 1px solid rgb(238, 238, 238);
  z-index: 9999999;
}

.szh-menu-container ul li:last-child {
  border-bottom: 0;
}

.tenant-menu .szh-menu-container ul {
  left: -80px !important;
  top: -10px !important;
}

.expense-menu .szh-menu-container ul {
  left: -90px !important;
  top: -10px !important;
}

.expense-filter-menu .szh-menu-container ul {
  left: -100px !important;
  top: 24px !important;
}

.meeting-sorting-menu.szh-menu-container ul {
  left: -100px !important;
  top: 24px !important;
}

.meeting-filtering-menu.szh-menu-container ul {
  left: -135px !important;
  top: 24px !important;
}

.my-invoice-sort .szh-menu-container ul,
.my-receipt-sort-menu .szh-menu-container ul {
  left: -75px !important;
  top: 10px !important;
}

.my-invoice-filter .szh-menu-container ul {
  left: -135px !important;
  top: 10px !important;
}

.my-receipt-filter-menu .szh-menu-container ul {
  left: -170px !important;
  top: 10px !important;
}

.expense-report-menu .szh-menu-container ul {
  left: -138px !important;
  top: 24px !important;
}

.total-expense .city-wise-heading .city-arabic-menu .szh-menu-container ul {
  left: -101.8px !important;
  top: 12px !important;
}

.contract-sort-menu .szh-menu-container ul {
  left: -160px !important;
  top: -10px !important;
}

.management-fee-menu .szh-menu-container ul {
  left: -170px !important;
  top: 20px !important;
}

.chat-menu .szh-menu-container ul {
  left: -120px !important;
  top: -8px !important;
}

.profile-menu .szh-menu-container ul {
  left: -210px !important;
  top: -10px !important;
}

.profile-menu_ar .szh-menu-container ul {
  left: -25px !important;
  top: -15px !important;
}

.family-menu .szh-menu-container ul {
  left: -90px !important;
  top: -5px !important;
}

.family-menu_ar .szh-menu-container ul {
  left: -25px !important;
  top: -15px !important;
}

.invoice-receipts-menu .szh-menu-container ul {
  left: -70px !important;
  top: -5px !important;
}

.complex-selection .szh-menu-container ul {
  left: -106px !important;
  top: -5px !important;
}

.invoice-receipts-menu .szh-menu-container ul li {
  padding: 8px 12px;
  display: block;
}

.myTeam-menu .szh-menu-container ul {
  left: -140px !important;
  top: -10px !important;
}

.list-unit-menu .szh-menu-container ul {
  left: -95px !important;
  top: -10px !important;
}

.unit-menu .szh-menu-container ul {
  left: -78px !important;
  top: -6px !important;
}

.unit-list-menu .szh-menu-container ul {
  left: -120px !important;
  top: 20px !important;
}

.pending-unit .szh-menu-container ul {
  left: -160px !important;
  top: -10px !important;
}

.personal-document-menu .szh-menu-container ul {
  left: -100px !important;
  top: -10px !important;
}

.saved-template-menu .szh-menu-container ul {
  left: -116px !important;
  top: -10px !important;
}

.document-menu .szh-menu-container ul {
  left: -100px !important;
  top: -8px !important;
}

.resolution-menu .szh-menu-container ul {
  left: -115px !important;
  /*top: 2px !important;*/
}

.chairman-notification-menu .szh-menu-container ul {
  left: -80px !important;
  top: 0px !important;
}

.meeting-menu .szh-menu-container ul {
  left: -114px !important;
  top: -4px !important;
}

.s-meeting-menu .szh-menu-container ul {
  left: -85px !important;
  top: -4px !important;
}

.filter-menu.szh-menu-container ul {
  left: -139.562px !important;
  top: 20px !important;
}

.sort-menu.szh-menu-container ul {
  left: -100px !important;
  top: 25px !important;
}

.chairman-top-menu.szh-menu-container ul {
  left: -190px !important;
  top: 45px !important;
  width: 180px;
}

.chairman-top-menu-ar.szh-menu-container ul {
  top: 45px !important;
}

.chairman-lang-menu.szh-menu-container ul {
  left: -47.475px !important;
  top: 12px !important;
}

.chairman-lang-menu.szh-menu-container li.active {
  color: #fc8434;
}

.chairman-complex-menu.szh-menu-container ul {
  left: -80px !important;
  top: 12px !important;
}

.chairman-complex-menu.szh-menu-container li.active {
  color: #fc8434;
}

.attendee-menu.szh-menu-container ul {
  left: -114.75px !important;
  top: -97px !important;
}

.szh-menu-container ul li {
  padding: 8px 32px;
  display: block;
  font-weight: 400;
  z-index: 9999999;
}

.chairman-top-menu.szh-menu-container ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.request-phone-number {
  position: relative;
}

.request-phone-number .react-tel-input {
  position: absolute;
  left: 0;
  top: 8px;
  z-index: 99999;
  width: fit-content;
  border-right: 1px solid #f0f0f0;
}

.request-phone-number .MuiInputBase-root {
  padding-left: 100px !important;
}

.classifiedListingMenu .MuiMenu-list .MuiListItem-root {
  padding: 1px 10px !important;
  width: 150px;
  min-height: 35px !important;
  display: flex !important;
  position: relative !important;
  text-align: left !important;
  align-items: center !important;
  justify-content: flex-start !important;
  text-decoration: none !important;
}

.family-menu button {
  padding: 0;
}

.menu-list .MuiListItem-root {
  padding: 6px 16px !important;
}

.unit-box-currency,
.measurement-modal-box {
  position: relative;
}

.unit-box-currency .unit-box-value {
  position: absolute;
  top: 34px;
  right: 18px;
  font-weight: 600;
}

.measurement-modal-value {
  position: absolute;
  top: 16px;
  right: 15px;
  font-weight: 600;
}

.measurement-arabic-value {
  position: absolute;
  top: 16px;
  left: 15px;
  font-weight: 600;
}

.share-box {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
  gap: 18px 36px;
}

.share-box button svg {
  width: 60px;
  height: 60px;
  border-radius: 5px;
}

.personal .dialog-button-group {
  flex-direction: column;
}

.personal .dialog-button-group button:first-child {
  background-color: #2b6fec;
  color: white;
  padding: 12px 30px;
  border-radius: 25px;
  font-weight: 600;
}

.personal .dialog-button-group button:last-child {
  margin-top: 10px;
  background-color: white;
  color: darkgray;
  border: 0;
  font-weight: 600;
}

.select-meeting .MuiListItemText-root {
  max-width: 275px;
}

.MuiDialog-root .dialog-button-group .add-button {
  background-color: #2b6fed;
  color: white;
  border-radius: 8px;
  width: 175px;
  font-weight: 600;
}

.MuiDialog-root .dialog-button-group {
  padding: 10px 24px 20px;
}

form {
  margin: 0;
}

.delete-document .dialog-button-group {
  padding: 10px 24px;
}

.MuiDialog-root .dialog-button-group .add-button.Mui-disabled {
  background-color: #2b6fed;
  color: white;
}

.MuiDialog-root .dialog-button-group .cancel-button {
  border: 1px solid #2b6fed;
  background-color: white;
  color: #2b6fed;
  border-radius: 8px;
  width: 175px;
  font-weight: 600;
}

.delete-document .dialog-button-group .cancel-button {
  margin-right: 20px;
}

.delete-document-dialog .MuiDialog-paperWidthSm {
  max-width: 500px !important;
}

.save-lease .MuiDialogContent-root {
  padding: 30px;
  padding-top: 30px !important;
}

.save-lease .input-box {
  padding: 10px 25px;
  border: 1px solid lightgray;
  font-size: 16px;
  outline: none;
  color: #494949;
  border-radius: 30px;
  background-color: #f9f9f9;
}

.save-lease .MuiDialogActions-root,
.penalty-dialog .MuiDialogActions-root {
  justify-content: center;
  align-items: center;
}

.save-lease .dialog-button-group .add-button,
.penalty-dialog .dialog-button-group .add-button {
  border-radius: 25px;
}

.penalty-dialog .dialog-button-group .add-button {
  width: 100%;
}

.penalty-dialog .dialog-button-group {
  padding: 10px 0 20px 0;
}

.penalty-dialog .select-with-icon {
  margin: 20px 0;
  background-color: #efefef;
  border-radius: 25px;
}

.penalty-dialog .select-with-icon .MuiListItemIcon-root {
  min-width: 30px;
}

.penalty-dialog .select-input {
  border-radius: 25px;
  padding: 12px 15px;
  background-color: #efefef;
}

.filter-head-box .select-year .MuiSelect-select {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 0px;
}

.penalty-dialog .select-input::before,
.penalty-dialog .select-input:hover,
.penalty-dialog .select-input::after,
.filter-head-box .select-year::before,
.filter-head-box .select-year:hover,
.filter-head-box .select-year::after {
  border: 0;
  outline: none !important;
  transition: none !important;
  content: none !important;
}

.filter-head-box {
  gap: 18px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.filter-head-box button,
.filter-head-box button:hover {
  color: white;
  padding: 7px 18px;
  background: #2b6fed;
  font-weight: 600;
  border-radius: 8px;
}

.filter-head-box button .MuiButton-startIcon {
  margin-left: 0;
}

.filter-head-box .select-year,
.filter-head-box .select-year:hover {
  font-size: 14px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  padding: 8px 12px;
  font-weight: 500;
  color: darkgray;
  min-width: 168px;
  max-width: 168px;
}

.filter-head-box .select-year .select-icon {
  color: darkgray;
  font-weight: 100;
}

.penalty-dialog .select-input .MuiInputAdornment-positionStart {
  margin-right: 20px;
}

.penalty-dialog h4 {
  font-weight: 600;
  margin-top: 20px;
  font-size: 18px;
}

.rent-history-dialog .select-input.input {
  margin-top: 18px;
  background-color: #f9f9f9;
  width: 100%;
  height: 56px;
  font-size: 16px;
  border: 1px solid #f0f0f0;
}

.rent-history-dialog .select-box .select-input {
  margin-top: 20px;
  background-color: #f3f3f3;
  width: 100%;
  padding: 0;
}

.rent-history-dialog .custom-input-box,
.rent-history-dialog .select-box {
  position: relative;
  width: 100%;
}

.rent-history-dialog .select-box .MuiSelect-select.MuiSelect-select {
  padding-left: 58px;
  border-radius: 50px;
}

.rent-history-dialog .custom-input-box .select-input.input {
  width: 100%;
  outline: none;
  padding: 10px 10px 10px 55px !important;
  font-size: 16px;
  border: 1px solid #f0f0f0;
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-width: 95%;
}

.rent-history-dialog .custom-input-box img,
.rent-history-dialog .select-box img {
  position: absolute;
  top: 38px;
  left: 16px;
}

.property-manager-dialog .select-input {
  height: 56px;
  background-color: #f9f9f9 !important;
}

.property-manager-dialog .select-input .MuiSelect-select {
  background-color: #f9f9f9 !important;
  border-radius: 50px !important;
}

.add-meeting .date-time .MuiInput-underline:before,
.add-meeting .dialog-select-input:before,
.add-meeting .date-time .MuiInput-underline:after,
.add-meeting .dialog-select-input:after,
.add-meeting .dialog-input::after,
.add-meeting .dialog-input::before {
  content: "";
  border: 0;
  outline: none;
  position: unset;
}

.add-meeting .date-time {
  /* display: flex; */
  align-items: center;
  border: 1px solid #f0f0f0;
  padding: 8px 12px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #f9f9f9;
  color: black;
  position: relative;
}

.add-meeting .date-time img {
  position: absolute;
  top: 14px;
  left: 12px;
}

.add-meeting .date-time input.date {
  width: 100%;
  border: 0;
  outline: none;
  background: #f9f9f9;
  padding: 4px 0 4px 30px;
  min-height: 32px;
  font-size: 16px;
  font-weight: 400 !important;
}
.add-meeting .date-time .time {
  padding-left: 30px;
}

.add-meeting .reject-note {
  border-radius: 5px;
  border: 1px solid lightgray;
  font-size: 16px;
  padding: 15px 26px 15px 12px;
  color: #494949;
  background-color: white;
  min-height: 150px !important;
  outline: none;
  margin-top: 20px;
  resize: none;
}

.add-meeting .dialog-input {
  margin-top: 15px;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: #f9f9f9;
  color: black;
  font-size: 16px;
}

.add-meeting .dialog-textarea {
  margin-top: 15px;
  border-radius: 8px;
  padding: 8px 12px;
  height: 125px !important;
  border: 1px solid #f0f0f0;
  outline: none;
  background-color: #f9f9f9;
  color: black;
  font-weight: 400 !important;
  font-size: 16px;
  resize: none;
}

.add-meeting .dialog-select-input {
  background-color: #f9f9f9;
  color: black;
  margin-top: 15px;
  padding: 8px 12px;
}

.add-meeting .MuiSelect-select.MuiSelect-select {
  background-color: #f9f9f9;
  color: black;
}

.add-meeting .dialog-textarea-input {
  height: 200px !important;
}

.add-meeting .invitee-list {
  height: 26px;
  background-color: #efefef;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 15px;
  color: gray;
  margin-top: 15px;
  cursor: pointer;
}

.add-meeting .create-audience {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 8px;
}

.add-meeting .create-audience span {
  color: #fc8434;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 15px;
}

.DraftEditor-root {
  background-color: #ececec;
}

.add-meeting .attendee-box {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 6px;
  flex-wrap: wrap;
}

.add-meeting .attendee-box .attendee {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  border: 1px solid #a6a6a6;
  gap: 8px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #a6a6a6;
  cursor: pointer;
}

.add-meeting .attendee-box .attendee span {
  padding: 10px 0 10px 0;
  font-size: 14px;
}

.add-meeting .attendee-box .attendee img {
  padding: 0 5px;
}

.add-meeting .attendee-box .attendee.active {
  border-color: #2b6fec;
  color: #2b6fec;
}

.add-meeting .szh-menu-container.attendee-menu li {
  color: black;
}

.add-meeting .MuiDialogContent-dividers {
  padding-top: 0 !important;
}

.select-meeting.scheduled-meeting .button-group .dialog-input {
  padding: 6px 12px;
  background-color: #f9f9f9;
  border: 1px solid #f0f0f0;
}

.select-meeting.scheduled-meeting .button-group {
  display: flex;
  align-items: center;
}

.select-meeting .MuiListItemIcon-root .MuiCheckbox-root {
  padding: 0;
}

.save-lease .input-box.MuiInput-underline:before,
.save-lease .input-box.MuiInput-underline:after,
.select-meeting.scheduled-meeting .button-group .MuiInput-underline:before,
.select-meeting.scheduled-meeting .button-group .MuiInput-underline:after,
.select-meeting.scheduled-meeting .filter .MuiInput-underline:before,
.select-meeting.scheduled-meeting .filter .MuiInput-underline:after {
  content: "";
  border: 0;
  outline: none;
  position: unset;
}

.select-meeting.scheduled-meeting td,
.select-meeting.scheduled-meeting th {
  padding-top: 0px;
  padding-bottom: 0px;
}

.select-meeting.scheduled-meeting th {
  font-weight: 600;
}

.select-meeting.scheduled-meeting .filter {
  display: flex;
  align-items: center;
  gap: 15px;
  overflow-y: hidden;
  padding-top: 32px;
  padding-bottom: 32px;
}

.select-meeting.scheduled-meeting .MuiTableCell-root {
  border-bottom: 0;
}

.select-meeting.scheduled-meeting .MuiTableRow-root {
  border-bottom: 1px solid #f4f4f4;
}

.select-meeting.scheduled-meeting .filter .dialog-select-input {
  width: 160px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  font-weight: 400;
  background-color: white;
}

.select-meeting.scheduled-meeting .filter .MuiSelect-root {
  background-color: white;
}

.select-meeting.scheduled-meeting .filter .filter-button {
  background-color: #2b6fed;
  color: white;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 125px;
  font-weight: 600;
}

.select-meeting.scheduled-meeting .selected-meeting h4 span {
  color: #fc8434;
}

.select-meeting.scheduled-meeting tr .MuiCheckbox-colorSecondary.Mui-checked {
  color: #fc8434;
}

.schedule-dialog .dialog-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-dialog .dialog-button-group button.add-button {
  border-radius: 25px !important;
}

.schedule-dialog .radio-select {
  width: 90%;
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  padding: 6px;
  margin: 5px 0;
}

.schedule-dialog .attendee-heading {
  padding-bottom: 0;
  text-align: right;
}

.schedule-dialog .choice-box {
  align-items: center;
}

.schedule-dialog .choice-box .active.radio-select .MuiTypography-root {
  color: #2b6fec;
  font-weight: 600;
}

.edit-profile .profile-picture {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.edit-profile .profile-picture p {
  cursor: pointer;
}

.add-meeting input::placeholder,
.meeting-group-dialog input::placeholder,
.invite-user-table input::placeholder,
.add-meeting textarea::placeholder {
  color: black !important;
  opacity: 1; /* Firefox */
}

.add-meeting input:-ms-input-placeholder,
.meeting-group-dialog input:-ms-input-placeholder,
.invite-user-table input:-ms-input-placeholder,
.add-meeting textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black !important;
}

.add-meeting input::-ms-input-placeholder,
.meeting-group-dialog input::-ms-input-placeholder,
.invite-user-table input::-ms-input-placeholder,
.add-meeting textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black !important;
}

.edit-profile .profile-picture .picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.edit-profile .profile-picture .picture.building {
  border: 2px solid lightgray;
}

.edit-profile .profile-picture .no-picture {
  border-radius: 50%;
  padding: 20px;
  background-color: #e3edff;
}

.edit-profile .profile-picture p {
  margin-top: 8px;
  color: #fc8434;
  font-weight: 600;
}

.edit-profile .profile-top {
  padding: 20px 50px;
}

.edit-profile .input-with-icon,
.edit-profile .select-with-icon {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  background: #f9f9f9;
  padding: 15px;
}

.edit-profile .measurement-box {
  position: relative;
}

.edit-profile .measurement-box img {
  position: absolute;
  top: 15px;
  left: 12px;
  opacity: 1;
  z-index: 9999999999;
}

.edit-profile .measurement-box .MuiSelect-root,
.edit-profile .measurement-box .MuiInput-root {
  background: #f9f9f9 !important;
}

.edit-profile .measurement-box .select-with-icon {
  padding: 10px 15px 10px 45px;
}

.edit-profile .mobile-box {
  display: flex;
  height: 100%;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.edit-profile .mobile-box .MuiSelect-select.MuiSelect-select {
  padding: 0 28px 0 20px;
}

.edit-profile .mobile-box .MuiListItemIcon-root {
  min-width: 20px;
}

.edit-profile .mobile-box .input.MuiInputBase-root {
  padding-left: 15px;
  border-left: 1px solid #aaaaaa;
}

.edit-profile .MuiInput-underline:after {
  content: "";
  border: 0;
  position: unset;
}

.edit-profile .MuiInput-underline:before {
  content: "";
  border: 0;
  position: unset;
}

.condition-modal .MuiDrawer-paper {
  padding: 0 20px;
  border-radius: 12px 12px 0 0;
}

.condition-modal .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0;
}

.condition-modal .button-group .add-more-button {
  border: 1px solid #2b6fed;
  background-color: white;
  color: #2b6fed;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 25px;
  font-weight: 600;
  min-width: 300px;
  width: 100%;
}

.condition-modal .button-group .add-button {
  background-color: #2b6fed;
  color: white;
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  width: 100%;
  min-width: 300px;
  height: 54px;
  font-size: 16px;
}

.condition-modal .condition-box .content-box {
  margin: 10px 0;
}

.condition-modal .condition-box h2 {
  margin: 20px 0;
}

.condition-modal .condition-box h2,
.condition-modal .condition-box h4 {
  font-weight: 600;
}

.condition-modal .condition-box.filter-box .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.condition-modal .condition-box .heading p {
  font-size: 18px;
  font-weight: 600;
}

.condition-modal .condition-box .heading span {
  font-weight: 600;
  color: #8d8d8d;
  text-transform: uppercase;
  cursor: pointer;
}

.condition-modal .condition-box.filter-box .content-box {
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;
}

.condition-modal .filter-box .accordion-box .MuiAccordion-root {
  box-shadow: none;
  border-bottom: 1px solid #f0f0f0;
}

.condition-modal .filter-box .accordion-box .MuiAccordionSummary-root {
  padding: 0;
}

.condition-modal .filter-box .accordion-box .MuiAccordionSummary-root p {
  font-weight: 600;
}

.condition-modal .filter-box .accordion-box .MuiAccordionSummary-expandIcon {
  transform: rotate(-90deg);
  padding: 0;
}

.condition-modal .filter-box .accordion-box .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(0deg);
  padding: 0;
}

.condition-modal .filter-box .accordion-box .MuiAccordionDetails-root {
  padding: 0;
}

.condition-modal .filter-box .accordion-box .MuiAccordionSummary-content,
.condition-modal .filter-box .accordion-box .MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0;
}

.condition-modal .filter-box .accordion-box .MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px;
}

.condition-modal .condition-box .condition {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 16px;
}

.condition-modal .condition-box .condition p {
  font-size: 16px;
}

.condition-modal .condition-box .condition > span {
  padding: 5px !important;
}

.condition-modal .condition-box .condition .condition-check {
  color: orange;
}

.go2072408551 {
  border-radius: 10px !important;
  background-color: #333 !important;
  color: #fff !important;
}

.error {
  color: red;
}

.add-document.resolutions .dialog-select-input,
.add-document.resolutions .dialog-textarea-input {
  background-color: #efefef;
  margin-top: 15px;
}

.chairman-logout .dialog-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.chairman-logout .dialog-button-group button.close {
  background-color: white;
  border-radius: 8px;
  color: #2b6fec;
  font-weight: 600;
  border: 2px solid #2b6fec;
  padding: 12px 30px;
}

.chairman-logout .dialog-button-group button.logout {
  background-color: #2b6fec;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  border: 1px solid #2b6fec;
  padding: 12px 30px;
  margin: 0;
}

.edit-building .upload-photo {
  height: 120px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 3px dashed #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.edit-unit .upload-photo {
  margin-left: 0px;
}

.edit-building .building-image img {
  height: 124px;
  width: 100%;
  border-radius: 8px;
}

.edit-profile button {
  padding-top: 9px;
  padding-bottom: 9px;
}

.edit-complex-details .MuiGrid-grid-md-3,
.edit-building-modal .MuiGrid-grid-md-3 {
  padding: 10px !important;
}

.edit-complex-details .photo-label,
.edit-building-modal .photo-label {
  margin-bottom: 14px;
}

.edit-building .building-image {
  position: relative;
}

.edit-building .building-image .delete-image {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.edit-building label {
  margin-bottom: 8px;
  color: black;
  font-size: 15px;
}

.edit-building .MuiInputBase-input {
  padding-left: 8px;
  padding-right: 8px;
}

.edit-building .about-us {
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  font-size: 16px;
  padding: 15px 26px 15px 12px;
  color: #494949;
  background-color: #f9f9f9;
  min-height: 150px !important;
  outline: none;
  width: 100%;
}

.edit-building .select-box .MuiSelect-root {
  border: 1px solid #c6c6c6;
  border-radius: 8px !important;
  background-color: #f9f9f9;
  padding: 15px;
}

.edit-building .select-box .MuiListItemIcon-root {
  min-width: 30px;
}

.edit-building .input-with-icon {
  padding: 10px;
}

.google-map-box {
  width: 100%;
  height: 500px;
}

.no-google-map-box {
  padding: 0 24px 16px;
}

.edit-profile form {
  margin: 0;
}

.edit-profile.edit-unit.MuiDialog-root .MuiDialogContent-dividers {
  border-bottom: 0;
}

.floor-plan-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 5px 26px 5px 5px;
  height: 42px;
  border: 1px solid #c6c6c6;
}

.floor-plan-box .MuiChip-root {
  background: white;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  color: black;
  height: 100%;
}

.floor-plan-box img {
  cursor: pointer;
}

.floor-plan-box .MuiChip-root img {
  width: 15px;
  height: 15px;
  margin: 0 12px 0 -6px;
}

.floor-plan-box .MuiChip-root span {
  margin-right: 12px;
  font-size: 14px;
}

.floor-plan-box .placeholder {
  color: darkgray;
  padding-left: 5px;
}

.edit-family-modal .MuiInput-root::before,
.edit-family-modal .MuiInput-root::after {
  content: "";
  border: 0;
  position: unset;
}

.edit-family-modal .MuiInput-root {
  border-radius: 5px;
  border: 1px solid lightgray;
  font-size: 16px;
  padding: 10px;
  color: #494949;
  background-color: #efefef;
}

.edit-family-modal .MuiSelect-root {
  background-color: #efefef !important;
}

.edit-family-modal input::placeholder,
.rent-history-dialog input::placeholder {
  color: black;
  opacity: 1;
}

.edit-family-modal input:-ms-input-placeholder,
.rent-history-dialog input:-ms-input-placeholder {
  color: black;
}

.edit-family-modal input::-ms-input-placeholder,
.rent-history-dialog input::-ms-input-placeholder {
  color: black;
}

.ril__navButtons {
  width: 135px !important;
  height: 135px !important;
}

.ril-prev-button {
  background: url("../assets/prev-image-icon.png") !important;
  left: 10% !important;
}

.ril-next-button {
  background: url("../assets/next-image-icon.png") !important;
  right: 10% !important;
}

.edit-unit .past-date {
  position: relative;
}

.edit-unit .past-date .input-with-icon {
  width: 100%;
  outline: none;
  padding-left: 40px;
  height: 54px;
  font-size: 16px;
}

.edit-unit .past-date img {
  position: absolute;
  top: 15px;
  left: 10px;
}

.edit-unit-modal-config {
  position: relative;
}

.edit-unit-modal-config img {
  position: absolute;
  z-index: 999;
  top: 18px;
  left: 10px;
}

.edit-unit-modal-config .select-input {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  background: #f9f9f9;
  width: 100%;
  padding-left: 35px;
}

.edit-unit-modal-config .select-input .select-icon {
  margin-right: 10px;
  color: darkgray;
  font-weight: 100;
}

.edit-unit-modal-config .select-input .MuiSelect-root {
  background: #f9f9f9;
}

.lease-expiration-modal h6 {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 20px;
}

.lease-expiration-modal .MuiDialogContent-root {
  padding-left: 8px;
  padding-right: 8px;
}

.lease-expiration-modal .dialog-select-input {
  border-radius: 35px;
  border: 1px solid #dedede;
  background-color: #f8f8f8;
  width: 90%;
  margin-bottom: 10px;
  padding: 18px;
}

.lease-expiration-modal .MuiSelect-root {
  background-color: #f8f8f8 !important;
  font-size: 16px;
  line-height: 20px;
  padding: 0;
}

.lease-expiration-modal .MuiInput-root::before,
.lease-expiration-modal .MuiInput-root::after,
.configuration-dialog .MuiInput-root::before,
.configuration-dialog .MuiInput-root::after {
  content: "";
  border: 0;
  position: unset;
}

.lease-expiration-set .dialog-button-group button.okay {
  background-color: #2b6fec;
  border-radius: 25px;
  color: white;
  font-weight: 600;
  border: 1px solid #2b6fec;
  padding: 12px 30px;
  margin: 0;
}

.lease-expiration-set .dialog-button-group button {
  width: 175px;
}

.configuration-dialog .MuiDialog-scrollPaper {
  align-items: flex-start;
}

.configuration-dialog .config-dialog-box {
  margin: 12px 0;
  display: flex;
  align-items: center;
}

.configuration-dialog .config-dialog-box .MuiInputBase-root {
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 8px 15px;
  width: 125px;
  margin: 0 8px;
}

.select-penalty-type {
  position: relative;
}

.select-penalty-type .MuiSelect-root {
  width: 100% !important;
  padding-left: 56px !important;
}

.select-penalty-type .select-with-icon {
  width: 100% !important;
}

.select-penalty-type img {
  position: absolute;
  left: 16px;
  top: 38px;
}

.submit-meeting-minute-dialog .MuiDialog-paperWidthSm {
  max-width: 500px;
}

.invite-user-modal .MuiDialog-paperWidthMd {
  max-width: 800px;
}

.submit-meeting-minute-dialog .MuiDialogContent-root {
  padding-top: 45px !important;
  padding-bottom: 28px !important;
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.configuration-dialog .config-dialog-box input::placeholder,
.save-lease input::placeholder,
.search-building input::placeholder,
.edit-profile input::placeholder,
.add-meeting input::placeholder,
.add-meeting textarea::placeholder,
.penalty-dialog input::placeholder {
  color: #7a7878 !important;
  opacity: 1; /* Firefox */
}

.configuration-dialog .config-dialog-box input:-ms-input-placeholder,
.save-lease input:-ms-input-placeholder,
.search-building input:-ms-input-placeholder,
.edit-profile input:-ms-input-placeholder,
.add-meeting input:-ms-input-placeholder,
.add-meeting textarea:-ms-input-placeholder,
.penalty-dialog input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7a7878 !important;
}

.configuration-dialog .config-dialog-box input::-ms-input-placeholder,
.edit-profile input::-ms-input-placeholder,
.search-building input::-ms-input-placeholder,
.add-meeting input::-ms-input-placeholder,
.add-meeting textarea::-ms-input-placeholder,
.penalty-dialog input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #7a7878 !important;
}

.dashboard-info-tooltip {
  background: white;
  color: black;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  max-width: 350px;
}

.dashboard-info-tooltip .tooltip-heading-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.config-hr {
  border: none;
  height: 0.5px;
  margin: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
  margin-bottom: 8px;
}

.profile-gender span {
  font-family: "Gotham_Bold", sans-serif !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #f9f9f9 !important;
}

.commonForm .formGroup textarea.incident-text-area {
  height: 130px !important;
}

.create-vehicle-select-box {
  border: 1px solid #f0f0f0;
  border-radius: 25px;
}

.create-vehicle-select-box .formInput {
  height: 56px;
  font-size: 16px;
  padding: 18px 18px 18px 56px;
}

.create-vehicle-select-box .formInput::after,
.create-vehicle-select-box .formInput::before {
  position: unset;
  transition: none;
  border-bottom: none;
  pointer-events: none;
  content: "";
}

.create-vehicle-select-box .formInput .MuiSelect-root {
  background-color: #f9f9f9 !important;
  padding-right: 0px !important;
}

.edit-profile .react-tel-input .country-list {
  position: fixed;
}

.incident-preview-document {
  position: relative;
  border: 1px solid lightgray;
  border-radius: 8px;
}

.incident-preview-document .play-icon {
  position: absolute;
  top: 38%;
  left: 38%;
}

.incident-preview-document .delete-image {
  position: absolute;
  z-index: 999;
  width: 26px;
  height: 26px;
  top: 5px;
  right: 5px;
}

.incident-preview-document img,
.incident-preview-document video {
  width: 100%;
  height: 100px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

@media only screen and (max-width: 425px) {
  .ril-prev-button {
    left: 0 !important;
  }

  .ril-next-button {
    right: 0 !important;
  }

  .MuiDialog-root .dialog-button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .MuiDialog-root .dialog-button-group button {
    width: 100% !important;
    margin: 0 !important;
  }

  .incident-wrapper .content-block-wrapper.common-incident-block .customButton.preview-incident-btn,
  .register-new-vehicle-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 12px;
    padding: 0;
  }

  .register-new-vehicle-btn {
    margin: 0;
  }

  .incident-wrapper .content-block-wrapper.common-incident-block {
    height: calc(100% - 120px);
  }

  .incident-wrapper.incident-details-wrapper-owner .content-block-wrapper.common-incident-block,
  .incident-wrapper.incident-list-owner .content-block-wrapper.common-incident-block {
    height: calc(100% - 0px);
  }

  .new-vehicle-submit-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    right: 0;
    padding: 12px 25px;
  }

  .new-vehicle-request-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px;
    width: 94% !important;
    margin: 0 !important;
  }

  .vehicle-list-owner {
    height: calc(100% - 130px);
    padding-bottom: 20px;
  }

  .register-another-vehicle-button {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .view-ticket-btn-incident {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  .MuiDialog-root .dialog-button-group.email-alert-dialog-button button {
    width: 200px !important;
  }
}

.incident-dialog-photo {
  width: 100%;
  height: 100%;
}

.video-react-has-started .video-react-control-bar {
  visibility: hidden !important;
}

.placeHolderForDate input[type="date"]:not(.has-value):before {
  color: #7a7878;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

.placeHolderForDate input[type="date"]:focus:before {
  content: "" !important;
}

.placeHolderForDate input[type="time"]:not(.has-value):before {
  color: #7a7878;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

.placeHolderForDate input[type="time"]:focus:before {
  content: "" !important;
}

.rent-history-dialog .MuiInputBase-input {
  padding: 8px 0;
}

.placeholderForClassified input::placeholder {
  color: #444444ff !important;
}

.budget-spending-menu .szh-menu-container ul {
  left: -75px !important;
  top: 15px !important;
}

.faq-complex-boxes {
  margin: 20px 0;
}

.faq-complex-boxes h4 {
  margin-bottom: 10px;
}

.faq-complex-boxes .document {
  box-shadow: 0px 2px 14px #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: "center";
  gap: 15px;
}

.faq-complex-boxes .document h6 {
  font-weight: 600;
}

.arabicIconChange .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(-90deg);
}

.arabic-editor .public-DraftStyleDefault-block {
  text-align: right;
}

.KeyboardForwardIconAR {
  transform: rotate(180deg);
}

.Mui-expanded .KeyboardForwardIconAR {
  transform: rotate(0deg);
}

.arabic-switch .MuiSwitch-switchBase {
  left: 38px !important;
}

.arabic-switch .MuiSwitch-switchBase.Mui-checked {
  left: 0px !important;
}

.arabic-grid .MuiSelect-icon {
  left: 5px;
  right: unset;
}

.select-arabic-menu {
  direction: rtl;
}

.arabic-grid .input.select-input .MuiInputAdornment-positionStart {
  margin-left: 25px !important;
  margin-right: 0px !important;
}

.arabic-grid .select-box img {
  right: 15px;
}

.arabic-grid .select-box .select-input {
  padding-right: 38px;
}

.arabic-grid .react-tel-input .form-control {
  padding-right: 60px;
  padding-left: 0px;
}

.arabic-grid .react-tel-input .flag-dropdown {
  right: 20px;
}

.arabic-grid .mobile-box .mobile-input .MuiInputBase-input {
  padding-left: 0px;
  padding-right: 15px;
}

.arabic-grid .unit-box-currency .unit-box-value {
  left: 18px !important;
  right: unset !important;
}

.rent-history-dialog .arabic-grid .custom-input-box img {
  right: 16px;
  left: unset;
}

.rent-history-dialog .arabic-grid .custom-input-box .select-input.input {
  padding: 10px 55px 10px 10px !important;
}

.arabic-grid .select-penalty-type img {
  right: 16px;
  left: unset;
}

.arabic-grid .select-penalty-type .MuiSelect-root {
  padding-right: 56px !important;
  padding-left: 24px !important;
}

.penalty-dialog .arabic-grid .select-input .MuiInputAdornment-positionStart {
  margin-right: 0px !important;
  margin-left: 20px !important;
}

.formInput_select_v .MuiSelect-select.MuiSelect-select {
  padding: 0px;
}

.formInput_select_s .MuiSelect-select.MuiSelect-select {
  padding-right: 15px;
}

.invite-user-modal .arabic-grid img {
  right: 20px;
  left: unset;
}

.invite-user-modal .arabic-grid .select-input .MuiSelect-root {
  padding-right: 50px;
}

.invite-user-modal .arabic-grid .request-phone-number .input-with-icon {
  padding-right: 100px;
  padding-left: unset !important;
}

.arabic-grid .request-phone-number .react-tel-input {
  left: unset;
  right: 0;
}

.formInput_select_family .MuiSelect-select.MuiSelect-select {
  padding-right: 15px !important;
}

.profileHobby_ar .MuiSvgIcon-root.MuiChip-deleteIcon {
  margin-left: 5px;
}

.editProfileBox input {
  margin-right: 35px;
  padding-left: 0px;
}

.measurement-modal-box .reject-note {
  width: 100% !important;
  min-height: 75px !important;
  border-color: #f0f0f0;
  margin-top: unset;
}

.action-assign-create-modal .select-input {
  padding-left: 10px;
}

.action-assign-create-modal .reject-note {
  background-color: #f9f9f9 !important;
}

.action-assign-create-modal.edit-unit.MuiDialog-root .MuiDialogContent-dividers {
  border: 0;
}

.action-assign-create-modal .action-assign-due-box .select-input {
  padding: 12px 25px 12px 35px;
}

.action-assign-create-modal .action-assign-due-box img {
  left: 15px;
}

.action-assign-create-modal input {
  background-color: #f9f9f9;
}

.no-resolution-box {
  padding: 15px;
  background: white;
  width: 100%;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 4px 0px 14px #e9e9e9;
}

button .MuiButton-startIcon {
  margin-left: 8px;
  margin-right: 8px;
}

.table-box-card {
  background-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 4px 0px 14px #e9e9e9;
}

.table-box-card .table-top {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-box-card .table-top h3 {
  font-weight: 600;
}

.table-box-card .table-top .search-box {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  padding: 5px 10px;
  border-radius: 8px;
}

.table-box-card .table-box .ellipse {
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -ms-inline-grid;
}

.table-box-card .table-box .ellipse-one {
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-box-card .table-bottom {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-box-card .table-bottom .current-page {
  color: #fc8434;
}

.table-box-card .table-bottom .total-page {
  font-weight: bold;
}

.table-box-card .table-bottom .MuiPaginationItem-root.Mui-selected {
  background-color: #fc8434;
  color: white;
  border-color: #fc8434;
}

.table-box-card .table-top .search-box svg {
  fill: gray;
}

.table-box-card .table-top .search-box .search {
  margin-left: 5px;
}

.table-box-card .table-box {
  margin: 20px;
  width: -webkit-fill-available;
}

.table-box-card .table-box .MuiTableCell-root {
  border: 0;
}

.table-box-card .table-box th.MuiTableCell-root {
  font-weight: bold;
}

.table-box-card .table-box td.MuiTableCell-root {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-box-card .table-box .MuiTableHead-root,
.table-box-card .table-box .MuiTableRow-root {
  border-bottom: 1px solid #f0f0f0;
}

.table-box-card .table-box .MuiTableRow-root td {
  text-transform: capitalize;
}

.table-box-card .table-box td.MuiTableCell-root button {
  padding: 0;
}

.table-box-card .table-box td.MuiTableCell-root span {
  padding: 5px 10px;
  border-radius: 25px;
}

.table-box-card .table-box td.MuiTableCell-root span.ellipse-one {
  padding: 0;
}

.slicker-images-box img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.slicker-images-box {
  margin-top: 20px;
  overflow: hidden;
}

.slicker-images-box .slick-track {
  display: flex;
}

.slicker-images-box .slick-prev,
.slicker-images-box .slick-next {
  display: none !important;
}

.faq-box .Mui-expanded {
  margin: unset !important;
}

.faq-box .MuiAccordionSummary-content.Mui-expanded {
  margin: 20px 0 !important;
}

.faq-box .MuiAccordion-root {
  margin: 2px 0 !important;
}
