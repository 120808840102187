.button-head{
  background-color: #fc8434 !important;
  color: white !important;
  height: 32px !important;
  width: auto !important;
  font-family: "Gotham_Bold", sans-serif !important;
  font-weight: bold !important; 
  padding:"10px 30px  30px 10px"!important;
  font-size: 16px !important;
}
.main-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-table{
  background-color: white;
  padding-bottom: 30px;

}
.country-dropdown{
  /* width: auto;
  height: 40px; */
  text-align: center;
}
.jurisdiction-search{
  background-color: blue!important;
  color: white!important;
  /* height: 56px; */
  width: auto;
  font-family: "Gotham_Bold", sans-serif !important;
  font-weight: bold !important;
  /* padding: 20px 20px !important; */
  padding:"20px 30px 30px  20px";
  /* margin:"20px 20px 20px 20px" */
}
.table-head-name{
  font-family: "Gotham_Bold", sans-serif !important;
 white-space: nowrap;
  display: flex;
  align-items: center;
  color: black;
  font-size: 22px !important;
}
.main-sub-table{
  display: flex;
justify-content: space-between;
 align-items: center;
 margin-top: 20px;
 margin-bottom: 20px;
 margin-left: 20px;
 margin-right: 20px;

}
.jur-heading{
  font-family: "Gotham_Bold", sans-serif !important;
  /* font-weight: bold; */
  font-size: 32px !important;
  white-space: nowrap !important;

}
.tableheader{
  font-family: "Gotham_Bold", sans-serif !important;
  font-weight: bold !important;
  font-size: 14px !important;
  color: black!important;
  text-align: center !important;
  white-space: nowrap;
}
.changeLabel{
font-family: "Gotham_Light", sans-serif !important;
color:grey!important;
margin-bottom: 10;

font-size: 14px !important;
text-align: left !important;
margin-top: 15px !important;
}


.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.modal-content {
  min-width: 50% !important;
  width: 700px ;
 /* max-height: 15% !important; */
  background-color: white;
  padding: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
   /* overflow-y: scroll; */
  /* overflow: auto; */
 
  /* position: absolute; */
  z-index: 10;
}
.modal-content-confirm {
  min-width: 30% !important;
  width: 400px ;
 min-height: 15% !important;
 max-height: 20% !important;
  background-color: white;
  padding: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
   /* overflow-y: scroll; */
  /* overflow: auto; */
 
  /* position: absolute; */
  z-index: 10;
}
#task-type-select1 {
  border-radius: 10px !important;
  background-color: #f0f0f0!important;
  color: gray;
}
.jur-text{
  text-align: justify !important;
}
.mainheading{
  font-family: "Gotham_Bold", sans-serif !important;

  text-align: left !important;
 
  font-size: 22px !important;

  color:black !important;
  display:flex !important;
  margin-bottom:10 !important;
}
.box-div-modal{
  height:300px;
  overflow-y:scroll;
  overflow-x:hidden;
  margin-top:10px;
  margin-bottom:10px
}
.headingdata{
  display: flex;
  justify-content: space-between;
}
.btn-cancel{
  /* border: 1px solid #2b6fed;  */
  border-radius: 8px;
  width: 175px;
  font-weight: 600;
   margin-top: 2% !important;
  margin-left: 15px !important;
  background-color:#f9f9f9!important;
  color:#2b6fed !important;
  /* padding: 10px 35px 10px 35px !important; */
  font-family: "Gotham_Bold", sans-serif !important;
  font-size: 16 !important;
  text-transform: none !important;
  border-color: #2196F3;
}
.edit-btn{
  margin-top: 2% !important;
  margin-left: 15px !important;
  background-color:#f9f9f9!important;
  color:#2b6fed !important;
  padding: 10px 35px 10px 35px !important;
  font-family: "Gotham_Bold", sans-serif !important;
  font-size: 16 !important;
  width: 130px !important;
  text-transform: none !important;
  font-weight: bold !important;
  border-color: #2196F3 !important;
  border: 2px solid  #2B6FED !important; 
}
.delete-btn{
  margin-top: 2% !important;
  margin-left: 15px !important;
  background-color:#f9f9f9!important;
  color:#2b6fed !important;
  padding: 10px 35px 10px 35px !important;
  font-family: "Gotham_Bold", sans-serif !important;
  font-size: 16 !important;
  width: 130px !important;
  text-transform: none !important;
  font-weight: bold !important;
  border-color: red;
  border: 2px solid red !important; 
}
.btn-cancel.MuiButtonBase-root{
  border: 2px solid black;
  border-color: #2196F3;
  color: dodgerblue
}
.btn-add{

  border-radius: 8px;
 
  font-weight: 600;

  margin-top: 2% !important;
  margin-left: 15px !important;
  background-color: #0096FF !important;
  color: white !important;
  font-family: "Gotham_Bold", sans-serif !important;
  font-size: 16px !important;
  /* padding: 10px 35px 10px 35px !important; */
  width: 175px !important;
  text-transform: capitalize !important;
  

}
.adjustBtn{
  /* position: relative; */
  margin-bottom: 20px;
  padding-right:31px ;
  display: flex;
  justify-content: flex-end;
}

.inputLabel1{

  font-family: "Gotham_Light", sans-serif !important;
  color:black!important;
  margin-bottom: 10;
 
  font-size: 14px !important;
  text-align: left !important;
  margin-top: 15px !important;
  /* margin-bottom: 15px !important; */
}
.formtype{
  background-color: #f0f0f0;
  height: 40px !important;
  text-align: left !important
}
.template{
  display: flex;
  justify-content: space-between;
}
.addtemplate{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f38384;
  font-family: "Gotham_Bold", sans-serif !important;
  font-size: 12px;
}
.timezone .MuiSelect-select:focus {
  background-color: #f0f0f0;
}
.timezone .MuiSelect-select {
  background-color: #f0f0f0;
  padding:0px 0px 0px 0px !important;
  height:45px !important
}
.timezone.MuiSelect-outlined.MuiSelect-outlined{
  border-radius: 0px !important;
 
}
.country .MuiSelect-outlined.MuiSelect-outlined{
  border-radius: 0px !important;
}
.timezone.MuiSelect-selectMenu{
  height: 1.1876em !important;
  min-height:  auto !important;
}
.add-value{
  background-color: #f0f0f0 !important;
}
.add-valueJuri{
  background-color: #f0f0f0 !important;
  height: 45px;
}
.timezone{
  align-items:center;
  background-color:#f0f0f0;
}
.btn-complex{
  display: flex;
  width: 200px;

}
.button-building{
  background-color: #fc8434 !important;
  color: white !important;
  /* height: 32px !important; */
  width: auto !important;
  font-family: "Gotham_Bold", sans-serif !important;
  font-weight: bold !important; 
  padding:14px 8px!important;
  white-space: nowrap;

}
/* .button-building .MuiButton-text{
padding:25px 8px !important
} */
.card-jurisdiction{
  width: auto;
  height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  padding:20px
}
.card_sub{
  display: flex;
  justify-content: space-between;
  padding:0px !important
}
.delete-btn-view{
  background-color:white !important;
  color: red !important;
  height: 55px !important;
  width: auto !important;
  font-family: "Gotham_Bold", sans-serif !important;
  font-weight: bold !important; 
  padding:"10px 30px  30px 10px"!important;
  white-space: nowrap;
  font-size: 15px;
 border-radius: 10px !important;
  
}
.edit-btn-jur{
  background-color: white!important;
  color:#2B6FED!important;
   /* height: 32px !important; */
  width: auto !important; 
  font-family: "Gotham_Bold", sans-serif !important;
  font-weight: bold !important; 
  padding:"10px 30px  30px 10px"!important;
  white-space: nowrap;
  height:55px;
 
  border-radius: 10px blue solid;
}
.test-search-jur{
  ::placeholder {
    color: red;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: red;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: red;
  }
}
.complex-btn{
  background-color: #2B6FED!important;
  color:white!important;
  height: 32px !important;
  width: auto !important;
  font-family: "Gotham_Bold", sans-serif !important;
  font-weight: bold !important; 
  padding:"10px 30px  30px 10px"!important;
  white-space: nowrap;
 
}
.ind-btn{
  background-color: #2B6FED!important;
  color:white!important;
  height: 32px !important;
  width: auto !important;
  font-family: "Gotham_Bold", sans-serif !important;
  font-weight: bold !important; 
  padding:"10px 30px  30px 10px"!important;
  white-space: nowrap;
 
}
.filters{
  display: flex;
}
.tablecell{
  background-color: #fafafa;
  max-width: 85px;
  /* overflow: auto; */
  text-align: left !important;
  word-wrap: nowrap;

}
.pagination{
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.sub-div{
  display: flex;
  flex-direction: row;
}
.minhead{
  font-family: "Gotham_Light", sans-serif !important;
  font-size: 16px !important;
  color: #2c6fed !important;
}
.subhead{
  font-family: "Gotham_Light", sans-serif !important;
  font-size: 16px !important;
  
}
.main-card{
  display: flex;
  flex-direction: row;
}
.card-info{
  margin-left: 15px;
}
.subheading-div{
  padding: 40px;
   padding-right: 40px
}
.text-jur{
  width: 300px !important
}
.div-maingrid{
  align-items: center;
}
.country{
  min-width: 200px;
  /* padding:14px */
  
}
.country> div{
  padding:12px !important
}
.country.MuiSelect-outlined.MuiSelect-outlined{
  border-radius:  0px !important;
}
.text-jur .MuiFormControl-fullWidth{
  width: 0 !important;
}

.view-select .MuiSelect-outlined.MuiSelect-outlined{
border-radius: 0px !important;
}

.view-heading{
font-size: 16px;
color: grey;
font-family: "Gotham_Light", sans-serif !important;
white-space: nowrap !important;
overflow: hidden;

}
.view-subvalue{
font-size: 16px;
color: black;
font-family: "Gotham_Light", sans-serif !important;
}
.view-search{
background-color: blue!important;
color: white!important;
width: auto;
font-family: "Gotham_Bold", sans-serif !important;
font-weight: bold !important; 
padding:"15px 15px" !important;
}
.add-complex{
display: flex;
justify-content: flex-end;
gap:30px;
align-items: center;
width: 100%;
}
.confirm-heading{
font-family: "Gotham_Bold", sans-serif !important;
white-space: nowrap;
 display: flex;
 align-items: center;
 color: black;
 font-size: 22px !important;
 justify-content: center;
}
.confirm-sub-heading{
font-family: "Gotham_Light", sans-serif !important;
white-space: nowrap;
 display: flex;
 align-items: center;
 color: black;
 font-size: 16px !important;
}
.confirm-adjustbtn{
display: flex;
justify-content: center;
}

.closeicon{
cursor: pointer;
}
.viewheading-data{
display: flex;
}