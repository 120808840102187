@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';

.login-wrapper {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  // background-color: $WHITE;
  background-color: $WHITE_SHADE1;
  overflow-x: hidden;
  position: relative;

  @include mq(tablet-landscape-up) {
    padding: 0;
    overflow-x: hidden;
    // margin: 20px auto;
  }

  & .logo-block {
    &.common-logo-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
    }

    & .chairman-menu {
      cursor: pointer;
    }
    & a {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-left: 20px;
    }

    & .head-logo {
      height: 80px;
      width: 80px;
      object-fit: contain;
    }

    & h4 {
      padding-left: 20px;
      font-weight: 600;
    }
  }

  & .auth-container {
    height: 100%;
    // display: flex;
    // align-items: center;
    //background-color: $WHITE_SHADE1;

    & .auth-cols {
      padding: 0;

      @include mq(tablet-landscape-up) {
        &:nth-child(2) {
          height: auto;
        }
      }
    }
  }

  & .main-content-block {
    & .classifiedWrapper {
      display: flex;
      flex-direction: column;
    }
    & .login-h1 {
      font-weight: bold;
    }
    width: 450px;
    margin: 0 auto;

    // &.no-classification-wrapper {
    //   height: 100%
    // }
    @include mq(tablet-landscape-up) {
      width: 95%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // height: 100%;
    }
  }

  & .content-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &.common_content_block {
      height: 100%;
      justify-content: flex-start;
    }

    @include mq(tablet-landscape-up) {
      padding: 0 15px;
      height: 100vh;
    }
  }

  & .right-block {
    padding: 30px 20px;

    @include mq(tablet-portrait-tablet-up) {
      padding: 30px;
    }

    & .building-logo {
      height: 93vh;
      width: 100%;
      object-fit: cover;
      border-radius: 30px;
    }
  }

  &.auth-wrapper {
    // justify-content: flex-start;
    & .main-content-block {
      justify-content: flex-start;
    }

    & .change-password-mainblock {
      justify-content: center;

      h1 {
        font-weight: bold;
      }
    }

    & .change-password-button-row {
      width: 80%;
      margin: 0 auto;

      @include mq(tablet-portrait-medium-up) {
        width: 40%;
      }
    }

    & .commonForm {
      // padding-top: 40px;
    }

    & .header-block {
      align-items: flex-start;
    }

    & .login-button-row {
      width: 90%;
      margin: 0 auto;
    }

    & .header-block-changepassword {
      align-items: center;

      & p {
        margin: 12px 0;
        text-align: center;
      }

      & h6 {
        text-align: center;
      }

      & .id-title {
        font-family: $Gotham_Medium;
        font-weight: bold;
      }
    }
  }

  & .footer-main-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .footer-block {
    width: 90%;
    margin: 0 auto;

    @include mq(tablet-portrait-medium-up) {
      width: 40%;
    }

    & .MuiButton-label {
      white-space: nowrap;
    }
  }

  & .customButton {
    padding-top: 40px;

    @include mq(tablet-landscape-up) {
      padding-top: 0px;
    }
  }

  & .link-block {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  @include mq(tablet-landscape-up) {
    width: 100%;
    padding: 0;
    margin: 20px auto;
  }

  & .row-banner {
    padding-bottom: 10px;

    @include mq(tablet-landscape-up) {
      width: 90%;
    }

    & .banner-img {
      width: 400px;
      height: 400px;
      object-fit: cover;
      border-radius: 10px;

      @include mq(tablet-landscape-up) {
        width: 100%;
        height: 250px;
      }
    }
  }

  & .tenant-logo {
    width: 200px;
    height: 65px;
    object-fit: contain;
  }

  & .lock-logo {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  & h1 {
    text-align: center;
    margin: 20px 0;
  }

  & h6 {
    margin: 10px 0;
    line-height: 20px;
    // text-align: center;
  }

  & .backIcon {
    cursor: pointer;
    position: absolute;
    left: 15px;

    @include mq(tablet-portrait-medium-up) {
      top: 50px;
    }

    & .MuiSvgIcon-root {
      // font-size: 45px;
      height: 1.5em;
      width: 1.5em;
    }
  }

  & .backIcon_ar {
    cursor: pointer;
    position: absolute;
    right: 15px;

    @include mq(tablet-portrait-medium-up) {
      top: 50px;
    }

    & .MuiSvgIcon-root {
      // font-size: 45px;
      height: 1.5em;
      width: 1.5em;
    }
  }

  & .row-btn {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mq(tablet-landscape-up) {
      // width: 90%;
      margin-top: 30px;
    }
  }

  & .header-block {
    // padding-top: 50px;
    &.chairmanHeaderBlock {
      align-items: center;

      & h6 {
        text-align: center;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 30px;

    @include mq(tablet-landscape-up) {
      align-items: center;
      padding-top: 70px;
    }

    &.header-left-block {
      align-items: flex-start;
    }

    & h1 {
      margin: 30px 0 5px 0;
    }

    & .text {
      color: $ORANGE;
      font-weight: bold;
    }
  }

  & .bottomBlock {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    @include mq(tablet-landscape-up) {
      // margin-bottom: 30px;
    }

    & .bottom-text {
      font-size: 11px;
      font-weight: 600;
      position: relative;
      color: $DARK;
      margin: 0;

      &::after,
      &::before {
        position: absolute;
        width: 100px;
        height: 0px;
        border: 1px solid $LIGHT_GRAY_SHADE1;
        top: 45%;
      }

      &::after {
        content: ' ';
        left: 95px;
      }

      &::before {
        content: ' ';
        left: -115px;
      }
    }

    & .tenant-logo {
      width: 120px;
    }

    @include mq(tablet-landscape-up) {
      // flex-direction: row;
      // justify-content: center;
    }

    & .link {
      // font-weight: 500;
      white-space: nowrap;
    }
  }

  & .passwordRow {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    & .link {
      & span {
        text-transform: capitalize;
      }
    }
  }

  & .link {
    color: $DARK;
    font-weight: bold;
    font-size: 14px;

    &:hover {
      text-decoration: none;
      color: $BLUE;
    }

    & span {
      text-transform: uppercase;
      color: $ORANGE;
      font-weight: bold;
      padding-left: 5px;
      font-family: $Gotham_Medium;
    }
  }
}
